<template>
  <div class="upload-additional-file">
    <div>
      <h2 class="upload-additional-file__title upload-additional-file__title--not-first">
        <span>{{ $t('objects.orders.text.file_upload_additional') }}</span>
        <div
          class="upload-additional-file__title__help-link"
          @click="triggerClick(trigger_click_type.MENU, trigger_click_content.TAKE_PICTURE_HELP_LINK)"
          @click.middle="triggerClick(trigger_click_type.MENU, trigger_click_content.TAKE_PICTURE_HELP_LINK)"
        >
          <a
            :href="take_picture_help_link"
            target="_blank"
          >
            <img src="/images/icon-help.svg">
          </a>
          <a
            :href="take_picture_help_link"
            target="_blank"
          >{{ $t('objects.orders.text.file_upload_help_link') }}
          </a>
        </div>
      </h2>
      <div class="upload-additional-file__notes">
        <p class="upload-additional-file__note upload-additional-file__note--red">
          {{ $t('objects.orders.text.file_upload_additional_note_1') }}
        </p>
        <p class="upload-additional-file__note upload-additional-file__note--red">
          {{ $t('objects.orders.text.file_upload_additional_note_2') }}
        </p>
        <p class="upload-additional-file__note upload-additional-file__note--red">
          {{ $t('objects.orders.text.file_upload_additional_note_3') }}
        </p>
      </div>
      <div class="upload-additional-file__drag">
        <input
          id="upload-additional-file"
          type="file"
          title=""
          multiple
          class="upload-additional-file__input upload-additional-file__input--hidden"
          :data-key="'file_upload'"
          :accept="order_uploaded_file.join(', ')"
          @change="fileChange"
          @click="clearValue($event)"
        >
        <label class="upload-additional-file__label">
          <span class="pc">{{ $t('objects.orders.text.drag_limit_title') }}<br></span>
          <span>{{ $t('objects.orders.text.drag_limit_size', { limit: uploading_limit }) }}</span>
        </label>
        <br>
        <label
          for="upload-additional-file"
          class="upload-additional-file__button"
        >
          {{ $t('objects.orders.text.select_files') }}
        </label>
      </div>
    </div>
    <div
      v-if="form_data.file_upload.length"
      class="upload-additional-file__preview"
    >
      <div class="upload-additional-file__preview__header">
        <div class="upload-additional-file__preview__header__title">{{ $t('objects.orders.text.file_uploaded_additional') }}</div>
        <common-button
          :class="['button-v2--primary','button-v2--150','upload-additional-file__preview__header__button']"
          @click="handleUploadFile"
        >
          <template #content>
            {{ $t('objects.orders.button.upload') }}
          </template>
        </common-button>
      </div>
      <div class="upload-additional-file__files">
        <div
          v-for="(item, key) in form_data.file_upload"
          :key="key"
          class="upload-additional-file__file"
        >
          <div
            :class="`upload-additional-file__inner upload-additional-file__inner--uploaded upload-additional-file__inner--${getExtension(item.name)}`"
            @click="lightBoxPdf(item)"
          >
            <pdf
              v-if="
                (getExtension(item.name) === file_extension.PDF) &&
                  is_rerender_pdf_file
              "
              :src="getUrlFile(item)"
            />
            <img
              v-else
              class="upload-additional-file__image"
              :src="getUrlFile(item)"
            >
          </div>
          <div class="upload-additional-file__information">
            <h3 class="upload-additional-file__file--name">
              {{ item.name }}
            </h3>
            <p class="upload-additional-file__file--extension">
              {{ getDate(item.lastModified) }}
            </p>
            <div
              class="upload-additional-file__remove"
              @click="removeFile(item, key, 'file_upload')"
            >
              <img src="/images/close-x.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalPDF
      v-if="is_show_modal"
      :url="pdf_url"
      :extension="extension"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
  import * as moment from 'moment-timezone'
  import pdf from 'vue-pdf'
  import { mapState } from 'vuex'
  import { showErrorModal } from '../../admin/utils/helpers'
  import {
    FILE_EXTENSION,
    FILE_NAME_MAX_LENGTH,
    MAX_FILE_UPLOAD,
    ORDER_UPLOAD_TYPE,
    TYPE_STL,
    TYPE_VIDEO,
    UPLOAD_MAX_FILE_SIZE
  } from '../../utils/constants'
  import { checkValidFileName, getExtension, getInvalidFileNameChars } from '../../utils/helpers'
  import Button from '../common/Button'
  import ModalPDF from '../modal/ModalPDF'

  export default {
    components: {
      ModalPDF,
      pdf,
      CommonButton: Button
    },
    emits: ['update-data'],
    data () {
      return {
        form_data: {
          file_upload: [],
          size_file_upload: 0
        },
        uploading_limit: UPLOAD_MAX_FILE_SIZE,
        pdf_url: '',
        is_show_modal: false,
        extension: '',
        order_uploaded_file: [...ORDER_UPLOAD_TYPE, ...TYPE_VIDEO, ...TYPE_STL],
        total_files: 0,
        is_rerender_pdf_file: true,
        file_extension: FILE_EXTENSION
      }
    },
    computed: {
      ...mapState('link_master', [
        'take_picture_help_link'
      ])

    },
    methods: {
      getExtension,
      fileChange: function (e) {
        const dataKey = e.target.getAttribute('data-key')
        const files = []
        const fileErrors = []

        if (!e.target.files.length || ['file_upload', 'upload_technician_instructions'].indexOf(dataKey) === -1) {
          return
        }

        if (e.target.files.length > MAX_FILE_UPLOAD) {
          showErrorModal(this.$t('new_validation.common.max_file_upload', { maximum: MAX_FILE_UPLOAD }))
          return
        }

        for (const item of e.target.files) {
          const size = item.size * Math.pow(10, -6)
          const checkFileValid = ((dataKey === 'file_upload') ? this.order_uploaded_file : this.upload_technician_instructions).filter(
            type => type === item.type
          )
          if (size === 0) {
            showErrorModal(this.$t('new_validation.order.invalid_file'))
            return
          }
          if (item.name.length > FILE_NAME_MAX_LENGTH) {
            showErrorModal(this.$t('new_validation.order.invalid_file_name_length', { max: FILE_NAME_MAX_LENGTH }))
            return
          }
          if (size > UPLOAD_MAX_FILE_SIZE) {
            showErrorModal(this.$t('new_validation.order.upload_max_file_size'))
            return
          }
          if (!checkValidFileName(item.name)) {
            const charsInvalid = getInvalidFileNameChars(item.name)
            showErrorModal(this.$t('new_validation.common.invalid_filename', { symbols: charsInvalid.join(' ') }))
            return
          }
          if (checkFileValid.length || this.getExtension(item.name) === this.file_extension.STL) {
            files.push(item)
            this.form_data.size_file_upload += size
          } else {
            fileErrors.push(this.$t('new_validation.order.invalid_file'))
          }
        }

        if (fileErrors.length) {
          showErrorModal(fileErrors.join('<br>'))
          return
        }

        if (dataKey === 'file_upload') {
          this.total_files += files.length

          if (this.total_files > MAX_FILE_UPLOAD) {
            showErrorModal(this.$t('new_validation.common.max_file_upload', { maximum: MAX_FILE_UPLOAD }))
            this.total_files -= files.length
            return
          }

          this.form_data.file_upload = [...this.form_data.file_upload, ...files]
        }
        if (dataKey === 'upload_technician_instructions') {
          this.form_data.upload_technician_instructions = [...files]
        }
        this.$emit('update-data', this.form_data)
      },
      getUrlFile: function (file) {
        if (file.url && typeof file.url !== 'undefined') {
          return file.url
        }

        if (TYPE_VIDEO.indexOf(file.type) > -1) {
          return '/images/icon-play.jpg'
        }

        if (this.getExtension(file.name) === this.file_extension.STL) {
          return '/images/icon-stl.svg'
        }

        if (this.order_uploaded_file.indexOf(file.type) > -1) {
          return URL.createObjectURL(file)
        }

        return ''
      },
      getDate: function (date) {
        return moment(new Date(date)).format('YYYY-MM-DD HH:mm')
      },
      removeFile: function (image, imageId, key) {
        this.form_data.size_file_upload -= image.size * Math.pow(10, -6)
        if (key === 'file_upload') {
          this.total_files--
          this.form_data.file_upload = this.form_data.file_upload.filter((item, key) => {
            return key !== imageId
          })
          this.is_rerender_pdf_file = false

          setTimeout(() => {
            this.is_rerender_pdf_file = true
          }, 10)
        }

        this.$emit('update-data', this.form_data)
      },
      lightBoxPdf: function (fileItem) {
        const url = this.getUrlFile(fileItem)
        const type = fileItem.type.split('/').shift()
        const fileExt = getExtension(fileItem.name)
        if (url && type !== 'video' && type !== 'model' && fileExt !== this.file_extension.STL) {
          this.pdf_url = url
          this.extension = type
          this.is_show_modal = true
        }
      },
      closeModal: function () {
        this.is_show_modal = false
      },
      clearValue (event) {
        event.target.value = ''
      },
      handleUploadFile () {
        this.$emit('upload-files')
      }
    }
  }
</script>
