import { helpers as vuelidateHelper } from 'vuelidate/lib/validators'

export const CLINIC_RECORD_TYPE = {
  CLINIC: '01210000000VBJxAAO',
  LAB_DESIGNER: '01210000000VDWQAA4'
}

export const PASSWORD_RESET_TYPE = {
  EXPIRED: 'EXPIRED',
  INFO: 'INFO',
  NONE: 'NONE'
}
export const RESPONSE_STATUS = {
  OK: 'OK',
  NG: 'NG'
}

export const INTERVIEW_COMMON_OPTIONS = {
  YES: 'はい',
  NO: 'いいえ'
}

export const INTERVIEW_OPTIONS_NO_SELECT = 'なし'
export const INTERVIEW_OPTIONS_NOT_ENTERED = '未入力'

export const RESPONSE_ERRORS = {
  EXIST: 'EXIST',
  INVALID: 'INVALID',
  INVALID_FILE_NAME_LENGTH: 'INVALID_FILE_NAME_LENGTH',
  IP_EXIST: 'IP_EXIST',
  MUST_BE_VALID_STL_FILE: 'MUST_BE_VALID_STL_FILE',
  NO_CSL_SUPPORTED: 'NO_CSL_SUPPORTED',
  NO_RESERVATION: 'NO_RESERVATION',
  NOT_FOUND: 'NOT_FOUND',
  PATIENT_DUPLICATE: 'PATIENT_DUPLICATE',
  RESERVATION_TYPE_DELETED: 'RESERVATION_TYPE_DELETED',
  RESERVATION_TYPE_USING: 'RESERVATION_TYPE_USING'
}

export const CURRENT_STATUS_RESERVATION = {
  NONE: 0,
  RESERVED: 1,
  CHECKED_IN: 2,
  BEFORE_ACTUARIAL: 3,
  PAID: 4
}

export const PATIENT_STATUS_MAP = ['patient.current_status_0', 'patient.current_status_1', 'patient.current_status_2', 'patient.current_status_3', 'patient.current_status_4']

export const ACTIVITY_LOG_TYPE = {
  MEDICAL_RECORD: 'medical_record',
  PATIENT: 'patient',
  INVOICE: 'invoice',
  RESERVATION: 'reservation',
  MEMO: 'memo',
  PATIENT_INTERVIEW: 'interview_sheet'
}
export const ACTIVITY_LOG_ACTION = {
  APPECT: 'accept',
  CANCEL: 'cancel',
  CANCEL_CHECK_IN: 'cancel_check_in',
  CHECK_IN: 'check_in',
  CHANGE_SCHEDULE: 'change_schedule',
  COMPLETE: 'complete',
  CREATE: 'create',
  DELETE: 'delete',
  ISSUE: 'issue',
  UPDATE: 'update',
  RECOVER: 'recover',
  RE_ISSUE: 're_issue'
}

export const AVAILABLE_PHONE_NUMBER_INTERVIEW = ['070', '080', '090']

export const RESPONSE_TYPE = {
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  INVALID: 'INVALID',
  INFO: 'INFO',
  NONE: 'NONE',
  OK: 'OK',
  NG: 'NG',
  ERROR: 'ERROR',
  EMAIL_INVALID: 'EMAIL_INVALID',
  MAXIMUM: 'MAXIMUM',
  DELETED: 'DELETED',
  LOCKED: 'LOCKED',
  HAS_ORDER_HISTORY: 'HAS_ORDER_HISTORY'
}

export const OTHER_STRING = 'その他'

export const SELECT_GENDER = [
  {
    value: 2,
    label: '男性'
  },
  {
    value: 1,
    label: '女性'
  }
]

export const FEMALE = 1
export const MALE = 2

export const PROFESSIONS = [
  {
    id: 0,
    label: '未入力'
  },
  {
    id: 1,
    label: '会社員'
  },
  {
    id: 2,
    label: '自営業'
  },
  {
    id: 3,
    label: '学生'
  },
  {
    id: 4,
    label: '主婦・主夫'
  },
  {
    id: 9,
    label: 'その他'
  }
]

export const PROFESSION_NOT_ENTERED_ID = 0
export const PROFESSION_OTHER_ID = 9

export const RESERVATION_STATUS = {
  RESERVED: 'reserved',
  CANCELLED: 'cancelled'
}

export const KIREILIGN_TYPE = 0
export const KIDS_BRAND_TYPE = 1
export const INVISALIGN_BRAND_TYPE = 2
export const CERAMIC_BRAND_TYPE = 3
export const KIYOCLEAR_BRAND_TYPE = 4
export const WIRE_BRAND_TYPE = 5
export const KYOSEI_GUIDE_BRAND_TYPE = 6
export const BRAVA_BRAND_TYPE = 7
export const GENERAL_BRAND_TYPE = 8

export const FULL_CALENDAR_TYPE = {
  VIEW: 'view',
  PRINT: 'print'
}

export const STAFF_POSITION = {
  DOCTOR: 'Dr',
  CONSELOR: 'DC',
  DENTAL_HYGIENIST: 'DH',
  DENTAL_ASSISTANT: ''
}

export const FILTER_STAFF_LIST = {
  ALL: {
    NAME: '全て表示'
  },
  IS_ADMIN: {
    NAME: 'マネージャ権限のみ',
    ID_FILTER: 1
  },
  NOT_ADMIN: {
    NAME: 'スタッフ権限のみ',
    ID_FILTER: 0
  },
  IS_DENTIST: {
    NAME: '歯科医師のみ',
    ID_FILTER: 1
  },
  CLEAN_STAFF: {
    NAME: '歯科衛生士のみ',
    ID_FILTER: 3
  },
  COUNSELORS: {
    NAME: 'コーディネーターのみ',
    ID_FILTER: 2
  },
  NORMAL_STAFF: {
    NAME: 'スタッフのみ',
    ID_FILTER: 4
  }
}

export const ROUTE_VISIBLE_RELOAD = [
  'reception',
  'dashboard'
]

export const PATIENT_DELETED_STATUS = {
  NOT_DELETED: 'not_deleted',
  DELETED: 'deleted'
}

export const PATIENT_STATUS = {
  NONE: 0,
  RESERVED: 1,
  CHECKED_IN: 2,
  BEFORE_ACTUALIAL: 3,
  PAID: 4
}

export const PATIENT_COLORS = {
  '#00c4ff': 1,
  '#77dd99': 2,
  '#eedd55': 3,
  '#ffaa44': 4,
  '#ee4455': 5,
  '#e6e6e6': 6
}

export const REVENUE_MAIL_STATUS = {
  ENABLE: 1,
  DISABLE: 0
}

export const TREATMENT_TYPE = {
  CLINIC: 'clinic',
  TREATMENT: 'treatment',
  MEMO: 'memo',
  CUSTOM: 'custom'
}

export const CATEGORY_TREATMENT_ID_CLINIC = 100

export const FILTER_CHAR_POSITION_STAFF = {
  all: 'すべて',
  a: 'あ',
  ka: 'か',
  sa: 'さ',
  ta: 'た',
  na: 'な',
  ha: 'は',
  ma: 'ま',
  ya: 'や',
  ra: 'ら',
  wa: 'わ'
}

export const SURVEY_STATUS = {
  UNPROCESSED: 0,
  APPROVED: 1,
  DISAPPROVED: 2
}
export const SURVEY_STATUS_TEXT = ['未処理', '承認', '非承認']

export const NOT_YET_ENTERED_QUESTIONS = [7, 8, 9, 10, 11, 17, 18, 19, 20, 21, 22, 23, 24, 'plan_to_move']

export const SYMPTOMS_TYPE = {
  RATTLING_TEETH: 'ガタガタした歯並びや八重歯（叢生）',
  PROTRUDING_TOOTH: '出っ歯（上顎前突）',
  MOUTH: '受け口（反対咬合）',
  CLEAR_TEETH: 'すきっ歯（空隙歯列）',
  FRONT_TEETH_DO_NOT_CLOSE: '前歯が閉じない（開咬）',
  OFF_CENTER: '中心がずれている（正中不一致）',
  DEEP_BITE: '噛み合わせが深い（過蓋咬合）',
  GUMMY_SMILE: 'スマイル時に上顎の歯肉が露出している（ガミースマイル）',
  LOGO: '口ゴボ',
  OTHER: 'その他'
}

export const COLUMN_TYPE = {
  NOT_AUTO: 0,
  AUTO: 1,
  CSL: 2,
  ADD_NEW: 999,
  EMPTY: 10000
}

export const ORDER_STATUS = {
  CHECKING: {
    value: 2,
    label: 'checking'
  },
  SETUP_DESIGN: {
    value: 3,
    label: 'setup-design'
  },
  PREPARE: {
    value: 4,
    label: 'prepare'
  },
  SHIPPED: {
    value: 5,
    label: 'shipped'
  },
  CANCELLED: {
    value: 6,
    label: 'cancelled'
  },
  FINISH: {
    value: 7,
    label: 'finish'
  }
}

export const UPLOADING_LIMIT = 100
export const UPLOAD_MAX_FILE_SIZE = 30
export const MAX_FILE_UPLOAD = 20
export const POST_MAX_SIZE = 300
export const FILE_NAME_MAX_LENGTH = 80

export const TYPE_VIDEO = [
  'video/quicktime',
  'video/mp4',
  '.mov'
]

export const TYPE_STL = [
  '.stl'
]

export const ORDER_UPLOAD_TYPE = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf'
]
export const INSTRUCTION_PRINTED = 1
export const TECHNICAL_INFO_PRINTED = 1

export const MODAL_TYPE_SETTINGS = {
  EDIT_TAB: 'EDIT_TAB',
  ADD_ITEM: 'ADD_ITEM',
  EDIT_ITEM: 'EDIT_ITEM'
}

export const SCREEN_WIDTH_MEDIUM = 1024
export const SCREEN_WIDTH_SMALL = 767
export const ORDER_FILE_TYPE = {
  INSTRUCTION: 1,
  ORDER_FILE: 0
}

export const ORDER_FILE_THUMB_STATUS = {
  LOADING: 0,
  LOADED: 1,
  FAILED: 2
}

export const SOCKET_CHANEL_TYPE = {
  SEARCH_ORDER: 0,
  PATIENT_ORDER: 1,
  DETAIL_ORDER: 2
}

export const FORBIDDEN = 'FORBIDDEN'
export const FORBIDDEN_CODE = 403

export const TEMPLATE_FORMAT = {
  FIRST_EXAMINATION: 1,
  RE_EXAMINATION: 2,
  FIRST_EXAMINATION_KIDS: 3,
  RE_EXAMINATION_KIDS: 4
}

export const TEMPLATE_FORMAT_KEY = {
  [TEMPLATE_FORMAT.FIRST_EXAMINATION]: 'first_examination',
  [TEMPLATE_FORMAT.RE_EXAMINATION]: 're_examination',
  [TEMPLATE_FORMAT.FIRST_EXAMINATION_KIDS]: 'first_examination_kids',
  [TEMPLATE_FORMAT.RE_EXAMINATION_KIDS]: 're_examination_kids'
}

export const TYPE_EXPORT_EXAMINATION = {
  FIRST_EXAMINATION: 1,
  RE_EXAMINATION: 2
}

export const VALIDATE_RULE = {
  CUSTOM_ALPHA_NUMERIC: vuelidateHelper.regex('customAlphaNumeric', /^[a-zA-Z0-9@.]+$/),
  // eslint-disable-next-line no-irregular-whitespace
  CUSTOM_JAPANESE_NAME: vuelidateHelper.regex('customJapaneseName', /^[一-龠ぁ-ゔァ-ヴーａ-ｚＡ-Ｚ０-９a-zA-Z0-9々〆〤 　]+$/)
}

export const INTERVIEW_TYPE = {
  FIRST: 1,
  REVISIT: 2
}

export const SF_PRE_CONTRACT_SIMULATION = {
  NOT_IMPLEMENTED: 0
}

export const LAYOUT_MODE = {
  AUTO: 0,
  PC: 1,
  SP: 2
}

export const TYPE_LINK_MASTERS = {
  SUPPORT_MATERIAL: 1,
  UPLOAD_HELP: 2,
  TAKE_A_PICTURE_HELP: 3
}

export const DOMAIN_SECOND_ID = 2

export const NOTIFICATION_FORCE_DISPLAY = {
  EVERY_TIME: 1,
  ONE_TIME: 2,
  NONE: 3
}

export const NOTIFICATION_READ = {
  NONE: 0,
  READ: 1
}

export const OTHER_REASON_PREFIX = 'その他：'

export const TRIGGER_CLICK_TYPE = {
  MENU: 'menu',
  PRINT: 'Print',
  SIMULATION: 'Simulation',
  PRINT_OH: 'PrintOH',
  SETUP_DATA: 'Setupdata'
}

export const TRIGGER_CLICK_CONTENT = {
  UPLOAD_HELP_LINK: '技工指示書の書き方',
  TAKE_PICTURE_HELP_LINK: '写真の撮り方'
}

export const UPDATE_RESERVATION_TYPE = {
  RESIZE_OR_DROP: 1,
  MOVE_MODE: 2
}

export const OPERATION_REASON_TYPE = {
  CREATE_RESERVATION: 1,
  UPDATE_RESERVATION: 2,
  CREATE_BLOCK: 3,
  UPDATE_BLOCK: 4
}

export const OPERATION_ALLOCATION_REASON_TYPE = {
  UPDATE: 1,
  DELETE: 2
}

export const PATIENT_SF_STATUS_C = {
  CANCELLED: 0,
  NEW: 1,
  NEW_APPLICATION: 2,
  VISITED: 4,
  EXISTS: 5,
  DELETED: 9
}

export const FILE_EXTENSION = {
  PDF: 'pdf',
  STL: 'stl'
}
