<template>
  <div class="patient-v2 patient-v2--normal">
    <patient-header-normal
      :custom-title="$t('objects.patient.text.title_editing')"
      @return="handleReturnAction"
      @submit="handleSubmitAction"
    />
    <div class="patient-v2__body">
      <div class="patient-v2__content">
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.basic_info') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-md-6 patient-v2__item">
              <label for="last_name">
                {{ $t('new_text.last_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="last_name"
                v-model="patient_form.last_name"
                :default-value="patient_form.last_name"
                name="last_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.last_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.last_name }]"
                type="text"
                maxlength="80"
                @input="updateFuriganaLast"
              />
              <span
                v-if="errors.last_name"
                class="error"
              >
                {{ errors.last_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="first_name">
                {{ $t('new_text.first_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="first_name"
                v-model="patient_form.first_name"
                :default-value="patient_form.first_name"
                name="first_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.first_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.first_name }]"
                type="text"
                maxlength="40"
                @input="updateFuriganaFirst"
              />
              <span
                v-if="errors.first_name"
                class="error"
              >
                {{ errors.first_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="kana_last_name">
                {{ $t('new_text.kata_last_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="kana_last_name"
                v-model="patient_form.kana_last_name"
                :default-value="patient_form.kana_last_name"
                name="kana_last_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.kata_last_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.kana_last_name }]"
                type="text"
                maxlength="191"
                @input="updateKanaLast"
              />
              <span
                v-if="errors.kana_last_name"
                class="error"
              >
                {{ errors.kana_last_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="kana_first_name">
                {{ $t('new_text.kata_first_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="kana_first_name"
                v-model="patient_form.kana_first_name"
                :default-value="patient_form.kana_first_name"
                name="kana_first_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.kata_first_name') })"
                :class="['patient-v2__input', { 'patient-v2__input--error': errors.kana_first_name }]"
                type="text"
                maxlength="191"
                @input="updateKanaFirst"
              />
              <span
                v-if="errors.kana_first_name"
                class="error"
              >
                {{ errors.kana_first_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="roma_last_name">
                {{ $t('new_text.roma_last_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="roma_last_name"
                v-model="patient_form.roma_last_name"
                :default-value="patient_form.roma_last_name"
                name="roma_last_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.roma_last_name') })"
                type="text"
                class="patient-v2__input"
                maxlength="191"
                @input="errors.roma_last_name=''"
              />
              <span
                v-if="errors.roma_last_name"
                class="error"
              >
                {{ errors.roma_last_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label for="roma_first_name">
                {{ $t('new_text.roma_first_name') }}
              </label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                id="roma_first_name"
                v-model="patient_form.roma_first_name"
                :default-value="patient_form.roma_first_name"
                name="roma_first_name"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.roma_first_name') })"
                type="text"
                class="patient-v2__input"
                maxlength="191"
                @input="errors.roma_first_name=''"
              />
              <span
                v-if="errors.roma_first_name"
                class="error"
              >
                {{ errors.roma_first_name }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.sex') }}</label>
              <div class="patient-v2__sex">
                <radio-common
                  name="sex"
                  class="patient-v2__sex__input"
                  :label="$t('new_text.placeholder.no_selection')"
                  :is-checked="patient_form.gender !== 1 && patient_form.gender !== 2"
                  @select="getDataGender"
                />
                <radio-common
                  name="sex"
                  class="patient-v2__sex__input"
                  :label="$t('new_text.male')"
                  :is-checked="patient_form.gender === 2"
                  :val="2"
                  @select="getDataGender"
                />
                <radio-common
                  name="sex"
                  class="patient-v2__sex__input"
                  :label="$t('new_text.female')"
                  :val="1"
                  :is-checked="patient_form.gender === 1"
                  @select="getDataGender"
                />
              </div>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.birthday') }}</label>
              <div class="patient-v2__birthday">
                <span class="patient-v2__birthday__group">
                  <select-common
                    :options="listYear"
                    name="birthdate_year"
                    :default-value="patient_form.birthdate_year"
                    key-value="value"
                    key-display="text"
                    @change="getLeapYear"
                  />
                  <span>{{ $t('new_text.year') }}</span>
                </span>
                <span class="patient-v2__birthday__group">
                  <select-common
                    :options="listMonth"
                    name="birthdate_month"
                    :default-value="patient_form.birthdate_month"
                    key-value="value"
                    key-display="text"
                    @change="value => patient_form.birthdate_month = value"
                  />
                  <span>{{ $t('new_text.month') }}</span>
                </span>
                <span class="patient-v2__birthday__group">
                  <select-common
                    :options="listDay"
                    name="birthdate_day"
                    :default-value="patient_form.birthdate_day"
                    key-value="value"
                    key-display="text"
                    @change="value => patient_form.birthdate_day = value"
                  />
                  <span>{{ $t('new_text.day') }}</span>
                </span>
              </div>
              <span
                v-if="errors.birthday"
                class="error"
              >
                {{ errors.birthday }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.brand_type') }}</label>
              <div class="patient-v2__brand">
                <select-common
                  :placeholder="$t('new_text.placeholder.please_select')"
                  :options="brand_masters"
                  name="brand_type"
                  key-value="type"
                  :disabled="disabledBrandType"
                  :default-value="patient_form.brand_type"
                  @change="setBrandType"
                />
              </div>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('objects.patient.text.treatmentend_status') }}</label>
              <div class="patient-v2__treatmentend-status">
                <select-common
                  :options="treatmentendStatus"
                  name="sf_treatmentend_status_c"
                  key-value="value"
                  key-display="label"
                  :default-value="patient_form.sf_treatmentend_status_c"
                  @change="value => patient_form.sf_treatmentend_status_c = value"
                />
              </div>
            </div>
            <div
              v-if="isDisplayedReasonForNonContract"
              class="col-md-12 patient-v2__item patient-v2__item--reason-for-non-contract"
            >
              <label>{{ $t('objects.patient.text.reason_for_non_contracting') }}</label>
              <span class="patient-v2__item__description">
                {{ $t('objects.patient.text.reason_for_non_contract_update_description') }}</span>
              <div class="patient-v2__item__bottom">
                <button
                  class="button-tertiary button-tertiary--small"
                  @click="setReasonForNonContractModalStatus(true)"
                >
                  {{ $t('new_text.button.select') }}
                </button>
                <span class="ws-pre-wrap patient-v2__item__content">{{ patient_form.reason_for_non_contract }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.contact_info') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-12 patient-v2__item">
              <label class="patient-v2__label__zipcode">{{ $t('new_text.zip_code') }}</label>
              <input-common
                v-model="patient_form.zip_code"
                name="zip_code"
                type="text"
                :class="['patient-v2__input patient-v2__input__zipcode', { 'patient-v2__input--error': errors.zip_code }]"
                :placeholder="$t('new_text.placeholder.no_hyphens_needed')"
                maxlength="7"
                @input="handleZipcodeChange"
              />
              <button
                class="button-v2 patient-v2__action__zipcode"
                @click="validateZipCode"
              >
                {{ $t('new_text.button.address_search') }}
              </button>
              <span
                v-if="errors.zipcode"
                class="error patient-v2__error-zipcode"
              >
                {{ errors.zipcode }}
              </span>
            </div>
            <div class="col-12 patient-v2__item">
              <label>{{ $t('new_text.address') }}</label>
              <input-common
                v-model="patient_form.address"
                name="address"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.address') })"
                class="patient-v2__input patient-v2__input--full-width"
                type="text"
              />
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.phone_number') }}</label>
              <span class="required">{{ $t('new_text.required') }}</span>
              <input-common
                v-model="patient_form.tel"
                name="tel"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.phone_number') })"
                :class="['patient-v2__input', {'patient-v2__input--error': errors.tel}]"
                type="text"
                maxlength="11"
                @input="handlePhoneChange"
              />
              <span
                v-if="errors.tel"
                class="error"
              >
                {{ errors.tel }}
              </span>
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('objects.patient.text.sms_delivery_setting') }}</label>
              <checkbox-common
                v-model="patient_form.is_receive_sms"
                name="is_receive_sms"
                :is-checked="patient_form.is_receive_sms === 1"
                :label="$t('new_text.receive_sms')"
              />
            </div>
            <div class="col-md-6 patient-v2__item">
              <label>{{ $t('new_text.email') }}</label>
              <input-common
                v-model="patient_form.email"
                name="email"
                :placeholder="$t('new_validation.common.required', { attribute: $t('new_text.email') })"
                :class="['patient-v2__input', {'patient-v2__input--error': errors.email}]"
                type="text"
                maxlength="80"
                @input="handleMailChange"
              />
              <span
                v-if="errors.email"
                class="error"
              >
                {{ errors.email }}
              </span>
            </div>
            <div
              v-if="false"
              class="col-md-6 patient-v2__item"
            >
              <label class="patient-page__label">{{ $t('objects.patient.text.mail_delivery_setting') }}</label>
              <checkbox-common
                v-model="patient_form.is_receive_email"
                name="is_receive_email"
                :is-checked="patient_form.is_receive_email === 1"
                :label="$t('new_text.receive_sms')"
              />
            </div>
          </div>
        </div>
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.assignee_info') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-12">
              <div class="position-wrapper">
                <div
                  v-if="person_in_charges.length"
                  class="position"
                >
                  <div
                    v-for="(item, index) in person_in_charges"
                    :key="index"
                    class="position__item"
                  >
                    <div class="position-left">
                      <p class="position-left__text">
                        {{ item.staff.position.name }}
                      </p>
                      <span class="position-left__space" />
                      <p class="position-left__text position-left__text--name">
                        {{ item.staff.name }}
                      </p>
                    </div>
                    <div
                      class="position-right"
                      @click="removePerson(item.staff_id)"
                    >
                      <img src="/images/icon-close-circle-gray.svg">
                    </div>
                  </div>
                  <button
                    class="btn btn-default position-btn person-charge__patient"
                    data-target="position"
                    type="button"
                    @click="showPersonModal"
                  >
                    <img
                      class="position-btn__icon"
                      src="/images/icon-plus-add.svg"
                    >
                    <p class="position-btn__text">
                      {{ $t('objects.patient.text.add_charge') }}
                    </p>
                  </button>
                </div>
                <div
                  v-else
                  class="position"
                >
                  <button
                    class="btn btn-default position-btn person-charge__patient"
                    data-target="position"
                    type="button"
                    @click="showPersonModal"
                  >
                    <img
                      class="position-btn__icon"
                      src="/images/icon-plus-add.svg"
                    >
                    <p class="position-btn__text">
                      {{ $t('objects.patient.text.add_charge') }}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-v2__row">
          <div class="col-12 patient-v2__item">
            <h2 class="patient-v2__row__title">
              {{ $t('objects.patient.text.other_notes') }}
            </h2>
          </div>
          <div class="patient-v2__row__group">
            <div class="col-12 patient-v2__item">
              <textarea
                v-model="patient_form.note"
                name="note"
                :placeholder="$t('new_text.placeholder.please_enter')"
                class="textarea-common"
                cols="2"
                rows="6"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading />
    <modal-select-person
      v-if="is_show_modal_select_person"
      :position-data="positionData"
      @person-data="updateStaffData"
      @close="is_show_modal_select_person = false"
    />
  </div>
</template>

<script>
  import {
    mbtrim,
    capitalize,
    isValidJapaneseName,
    checkValidEmail,
    checkValidPhone,
    showErrorModal,
    mbTrimRomaji
  } from '../../../utils/helpers'
  import Loading from '../../../components/Loading'
  import { Core as YubinBangoCore } from 'yubinbango-core'
  import ModalSelectPerson from '../../../components/v2/ModalSelectPerson'
  import * as moment from 'moment-timezone'
  import historykana from 'historykana'
  import * as wanakana from 'wanakana'
  import PatientHeaderNormal from '../../../components/patient/v2/PatientHeaderNormal'
  import InputCommon from '../../../components/common/Input'
  import RadioCommon from '../../../components/common/Radio'
  import CheckboxCommon from '../../../components/common/Checkbox'
  import SelectCommon from '../../../components/common/Select'
  import { get } from 'lodash'
  import {
    CERAMIC_BRAND_TYPE,
    INVISALIGN_BRAND_TYPE,
    KIDS_BRAND_TYPE,
    KIREILIGN_TYPE,
    KIYOCLEAR_BRAND_TYPE,
    WIRE_BRAND_TYPE,
    KYOSEI_GUIDE_BRAND_TYPE,
    DOMAIN_SECOND_ID,
    BRAVA_BRAND_TYPE,
    GENERAL_BRAND_TYPE
  } from '../../../utils/constants'

  export default {

    components: {
      ModalSelectPerson,
      Loading,
      CheckboxCommon,
      SelectCommon,
      RadioCommon,
      InputCommon,
      PatientHeaderNormal
    },
    data () {
      return {
        patient_form: {
          last_name: '',
          first_name: '',
          kana_last_name: '',
          kana_first_name: '',
          roma_last_name: '',
          roma_first_name: '',
          gender: '',
          zip_code: '',
          prefecture: '',
          city: '',
          address: '',
          tel: '',
          email: '',
          birthdate_year: '',
          birthdate_month: '',
          birthdate_day: '',
          note: '',
          brand_type: '',
          color: '',
          is_receive_sms: false,
          is_receive_email: 0,
          sf_treatmentend_status_c: null,
          reason_for_non_contract: ''
        },
        patient_id: '',
        reservation_id: '',
        serviceSummary: '',
        error_messages: [],
        history_last: [],
        history_first: [],
        history_kata_last: [],
        history_kata_first: [],
        person_in_charges: [],
        errors: {
          last_name: '',
          first_name: '',
          kana_last_name: '',
          kana_first_name: '',
          tel: '',
          email: '',
          birthday: ''
        },
        is_show_modal_select_person: false,
        brand_masters: [],
        treatmentendStatus: [
          {
            value: null,
            label: '-'
          },
          {
            value: 1,
            label: this.$t('objects.patient.sf_treatmentend_status_c.1')
          },
          {
            value: 2,
            label: this.$t('objects.patient.sf_treatmentend_status_c.2')
          },
          {
            value: 3,
            label: this.$t('objects.patient.sf_treatmentend_status_c.3')
          },
          {
            value: 4,
            label: this.$t('objects.patient.sf_treatmentend_status_c.4')
          },
          {
            value: 5,
            label: this.$t('objects.patient.sf_treatmentend_status_c.5')
          }
        ]
      }
    },
    computed: {
      isValidBirthday: function () {
        return (!this.patient_form.birthdate_year && !this.patient_form.birthdate_month && !this.patient_form.birthdate_day) ||
          (this.patient_form.birthdate_year && this.patient_form.birthdate_month && this.patient_form.birthdate_day)
      },
      patient: function () {
        return this.$store.getters.get_patient
      },
      positionData: function () {
        return this.patient.form_data.staffs
      },
      listYear: function () {
        return this.setOptionsNumber(new Date().getFullYear(), 1920)
      },
      listMonth: function () {
        return this.setOptionsNumber(12)
      },
      listDay: function () {
        return this.getDateData()
      },
      currentClinic () {
        return this.$store.getters.currentClinic
      },
      disabledBrandType () {
        return this.patient.info.brand_type === INVISALIGN_BRAND_TYPE ||
          this.patient.info.brand_type === CERAMIC_BRAND_TYPE ||
          this.patient.info.brand_type === KIYOCLEAR_BRAND_TYPE ||
          this.patient.info.brand_type === WIRE_BRAND_TYPE ||
          this.patient.info.brand_type === KYOSEI_GUIDE_BRAND_TYPE ||
          this.patient.info.brand_type === BRAVA_BRAND_TYPE ||
          this.patient.info.brand_type === GENERAL_BRAND_TYPE ||
          (this.patient.info.brand_type === KIREILIGN_TYPE && this.patient.support_domain_id === DOMAIN_SECOND_ID)
      },
      reasonForNonContract: function () {
        return this.$store.getters.get_reasonForNonContract
      },
      isDisplayedReasonForNonContract () {
        return get(this.patient, 'order_count', 0) === 0
      }
    },
    watch: {
      patient () {
        this.loadPatientInfo(this.patient)
      },
      reasonForNonContract (newValue) {
        this.patient_form.reason_for_non_contract = newValue
      },
      currentClinic () {
        this.loadPatientInfo(this.patient)
      }
    },
    created () {
      this.patient_id = this.$route.params.id
      const currentPatientId = this.$store.getters.get_patient.id
      if (this.patient_id !== currentPatientId) {
        this.$store.commit('reset_patientState')
        this.getPatientDetail()
      }

      document.title = this.$t('objects.page_title.patient').toString()
      this.reservation_id = this.$route.query.reservation_id
      this.loadPatientInfo(this.patient)
    },
    mounted: function () {
      this.$nextTick(function () {
        const romaFirstNameInput = $('#roma_first_name')
        const romaLastNameInput = $('#roma_last_name')
        romaFirstNameInput.change(function (e) {
          romaFirstNameInput.val(mbTrimRomaji(romaFirstNameInput.val()))
        })
        romaLastNameInput.change(function (e) {
          romaLastNameInput.val(mbTrimRomaji(romaLastNameInput.val()))
        })
      })
    },
    methods: {
      getDataGender (value) {
        this.patient_form.gender = value
      },
      setOptionsNumber (end, start = 1) {
        const options = []

        for (let i = start; i <= end; i++) {
          options.push({ value: i, text: i })
        }

        return options
      },
      handleReturnAction () {
        this.$router.push({ name: 'patient.detail' })
      },
      getPatientDetail () {
        this.$store.commit('set_loading', true)
        this.$store.dispatch('getPatientDetail', {
          id: this.patient_id,
          reservation_id: this.reservation_id,
          accept_deleted: this.acceptDeleted
        }).finally(() => {
          this.$store.commit('set_loading', false)
        })
      },
      loadPatientInfo: function (data) {
        const personInChargesObj = data.personInCharges || {}
        const personInCharges = Object.keys(personInChargesObj).map((key) => personInChargesObj[key])
        this.person_in_charges = [...personInCharges]
        this.patient_form.last_name = data.info.last_name
        this.patient_form.first_name = data.info.first_name
        this.patient_form.kana_last_name = data.info.kana_last_name
        this.patient_form.kana_first_name = data.info.kana_first_name
        this.patient_form.roma_last_name = data.info.roma_last_name
        this.patient_form.roma_first_name = data.info.roma_first_name
        this.patient_form.gender = data.info.gender
        this.patient_form.zip_code = data.info.zip_code ?? ''
        this.patient_form.prefecture = data.info.prefecture ?? ''
        this.patient_form.city = data.info.city ?? ''
        this.patient_form.address = data.info.address ?? ''
        this.patient_form.tel = data.info.tel
        this.patient_form.email = data.info.email
        this.patient_form.note = data.info.note
        this.patient_form.color = data.info.color
        this.patient_form.is_receive_sms = data.info.is_receive_sms
        this.patient_form.is_receive_email = parseInt(data.info.is_receive_email) ?? 0
        this.patient_form.brand_type = !data.info.brand_type ? 0 : data.info.brand_type
        this.patient_form.sf_treatmentend_status_c = data.sf_treatmentend_status_c
        this.patient_form.reason_for_non_contract = data.info.reason_for_non_contract
        this.setReasonForNonContract(this.patient_form.reason_for_non_contract)
        const birthOfDate = data.info.birth_of_date
        if (birthOfDate) {
          const [year, month, day] = (birthOfDate.match(/\d+/g)).map(Number)
          this.patient_form.birthdate_year = year
          this.patient_form.birthdate_month = month
          this.patient_form.birthdate_day = day
        }
        this.brand_masters = get(data, 'form_data.brandMasters') || []

        if (this.currentClinic.is_kids_only) {
          this.brand_masters = this.brand_masters.filter(item => item.type === KIDS_BRAND_TYPE)
          this.patient_form.brand_type = KIDS_BRAND_TYPE
        } else if (data.info.brand_type === KIREILIGN_TYPE || data.info.brand_type === KIDS_BRAND_TYPE) {
          this.brand_masters = this.brand_masters.filter(item => item.type === KIDS_BRAND_TYPE || item.type === KIREILIGN_TYPE)
        }
      },
      getDateData: function () {
        if (this.patient_form.birthdate_year && this.patient_form.birthdate_month) {
          const dateData = new Date(`${this.patient_form.birthdate_year}-${this.patient_form.birthdate_month}-01`)
          const dateOfMonth = moment(dateData).endOf('month').date()

          if (this.patient_form.birthdate_day && this.patient_form.birthdate_day > dateOfMonth) {
            this.patient_form.birthdate_day = ''
          }

          return this.setOptionsNumber(dateOfMonth, 1)
        }

        this.patient_form.birthdate_day = ''

        return []
      },
      updateFuriganaLast (value) {
        this.errors.last_name = ''
        if (value === '') {
          this.history_last = []
        }

        this.history_last.push(value)
        this.patient_form.kana_last_name = historykana(this.history_last)

        if (this.patient_form.kana_last_name) {
          this.errors.kana_last_name = ''
        }

        this.patient_form.roma_last_name = wanakana.toRomaji(this.patient_form.kana_last_name).toUpperCase()
        this.patient_form.roma_last_name = this.patient_form.roma_last_name.length > 191
          ? this.patient_form.roma_last_name.substring(0, 191) : this.patient_form.roma_last_name
      },
      updateFuriganaFirst (value) {
        this.errors.first_name = ''
        if (value === '') {
          this.history_first = []
        }

        this.history_first.push(value)
        this.patient_form.kana_first_name = historykana(this.history_first)

        if (this.patient_form.kana_first_name) {
          this.errors.kana_first_name = ''
        }

        this.patient_form.roma_first_name = capitalize(wanakana.toRomaji(this.patient_form.kana_first_name))
        this.patient_form.roma_first_name = this.patient_form.roma_first_name.length > 191
          ? this.patient_form.roma_first_name.substring(0, 191) : this.patient_form.roma_first_name
      },
      updateKanaLast (value) {
        this.errors.kana_last_name = ''
        this.errors.roma_last_name = ''
        if (value === '') {
          this.history_kata_last = []
        }

        this.history_kata_last.push(value)
        this.patient_form.roma_last_name = wanakana.toRomaji(historykana(this.history_kata_last)).toUpperCase()
        this.patient_form.roma_last_name = this.patient_form.roma_last_name.length > 191
          ? this.patient_form.roma_last_name.substring(0, 191) : this.patient_form.roma_last_name
      },
      updateKanaFirst (value) {
        this.errors.kana_first_name = ''
        this.errors.roma_first_name = ''
        if (value === '') {
          this.history_kata_first = []
        }

        this.history_kata_first.push(value)
        this.patient_form.roma_first_name = capitalize(wanakana.toRomaji(historykana(this.history_kata_first)))
        this.patient_form.roma_first_name = this.patient_form.roma_first_name.length > 191
          ? this.patient_form.roma_first_name.substring(0, 191) : this.patient_form.roma_first_name
      },
      getPositionName (data) {
        return data?.staff?.position?.name
      },
      getLeapYear (value) {
        this.patient_form.birthdate_year = value
        this.is_leap_year = (this.patient_form.birthdate_year % 4 === 0 &&
          this.patient_form.birthdate_year % 100 !== 0 &&
          this.patient_form.birthdate_year % 400 !== 0) ||
          (this.patient_form.birthdate_year % 100 === 0 &&
            this.patient_form.birthdate_year % 400 === 0)
      },
      resetErrors () {
        this.error_messages = []
        this.errors = {
          last_name: '',
          first_name: '',
          kana_last_name: '',
          kana_first_name: '',
          tel: '',
          email: '',
          birthday: ''
        }
      },
      validateSubmitData: function (data) {
        this.resetErrors()

        if (!mbtrim(data.last_name)) {
          this.errors.last_name = this.$t('new_validation.patient.last_name_required')
        }

        if (!isValidJapaneseName(data.last_name)) {
          this.errors.last_name = this.$t('new_validation.patient.patient_name_invalid')
        }

        if (!mbtrim(data.first_name)) {
          this.errors.first_name = this.errors.first_name = this.$t('new_validation.patient.first_name_required')
        }

        if (!isValidJapaneseName(data.first_name)) {
          this.errors.first_name = this.$t('new_validation.patient.patient_name_invalid')
        }

        if (!mbtrim(data.kana_last_name)) {
          this.errors.kana_last_name = this.$t('new_validation.patient.kana_last_name_required')
        }

        if (mbtrim(data.kana_last_name) && !(/^([ぁ-ん]+)$/g.test(data.kana_last_name))) {
          this.errors.kana_last_name = this.$t('new_validation.patient.please_enter_hiragana')
        }

        if (!mbtrim(data.kana_first_name)) {
          this.errors.kana_first_name = this.$t('new_validation.patient.kana_first_name_required')
        }

        if (mbtrim(data.kana_first_name) && !(/^([ぁ-ん]+)$/g.test(data.kana_first_name))) {
          this.errors.kana_first_name = this.$t('new_validation.patient.please_enter_hiragana')
        }

        if (!mbtrim(data.roma_last_name)) {
          this.errors.roma_last_name = this.$t('new_validation.patient.roma_last_name_required')
        }

        if (!mbtrim(data.roma_first_name)) {
          this.errors.roma_first_name = this.$t('new_validation.patient.roma_first_name_required')
        }

        if (this.patient_form.zip_code &&
          (this.patient_form.zip_code.length !== 7 || !$.isNumeric(this.patient_form.zip_code))) {
          this.errors.zipcode = this.$t('new_validation.patient.zipcode_greater_than_7')
        }

        if (data.tel && !(/^\d{10}$|^\d{11}$/.test(data.tel))) {
          this.errors.tel = this.$t('new_validation.patient.phone_number_invalid')
        }

        if (!data.tel) {
          this.errors.tel = this.$t('new_validation.common.required',
                                    { attribute: this.$t('new_text.phone_number') })
        }

        if (data.email && !checkValidEmail(data.email)) {
          this.errors.email = this.$t('new_validation.common.invalid_email')
        }

        if (!this.isValidBirthday) {
          this.errors.birthday = this.$t('new_validation.patient.correct_birthday')
        }

        Object.values(this.errors).forEach(val => {
          if (val) {
            this.error_messages.push(val)
          }
        })

        if (this.error_messages.length) {
          showErrorModal(this.$t('objects.patient.message.has_error_input'))
          return false
        }

        return true
      },
      handleSubmitAction: function () {
        const submitData = {
          patient_id: this.patient_id,
          last_name: this.patient_form.last_name.trim(),
          first_name: this.patient_form.first_name.trim(),
          roma_first_name: this.patient_form.roma_first_name.trim(),
          roma_last_name: this.patient_form.roma_last_name.trim(),
          kana_first_name: this.patient_form.kana_first_name.trim(),
          kana_last_name: this.patient_form.kana_last_name.trim(),
          gender: this.patient_form.gender === 0 ? null : this.patient_form.gender,
          zip_code: this.patient_form.zip_code,
          prefecture: this.patient_form.prefecture,
          city: this.patient_form.city,
          address: this.patient_form.address,
          tel: this.patient_form.tel,
          email: this.patient_form.email,
          person_in_charges: this.person_in_charges.map(item => item.staff_id),
          note: this.patient_form.note,
          color: this.patient_form.color,
          brand_type: this.patient_form.brand_type,
          is_receive_sms: this.patient_form.is_receive_sms,
          is_receive_email: this.patient_form.is_receive_email,
          sf_treatmentend_status_c: this.patient_form.sf_treatmentend_status_c,
          reason_for_non_contract: this.patient_form.reason_for_non_contract
        }

        if (this.patient_form.birthdate_year && this.patient_form.birthdate_month && this.patient_form.birthdate_day) {
          submitData.birth_of_date =
            `${this.patient_form.birthdate_year}-${this.formatDayOrMonth(this.patient_form.birthdate_month)}-${this.formatDayOrMonth(this.patient_form.birthdate_day)}`
        }

        if (!this.validateSubmitData(submitData)) return

        this.$store.commit('set_loading', true)
        this.$store.dispatch('updatePatientInfo', submitData).then((result) => {
          if (result.status === 200) {
            this.$store.commit('set_loading', false)
            const patientDetailPath = this.$router.resolve({
              name: 'patient.detail',
              params: { id: this.patient_id },
              query: { reservation_id: this.reservation_id }
            }).href

            window.location.href = window.location.origin + patientDetailPath
          }
        }).catch(() => {
          const errorMessage = this.$t('message.something_went_wrong')
          showErrorModal(errorMessage, this)
          this.$store.commit('set_loading', false)
        })
      },
      validateZipCode: function () {
        if (this.patient_form.zip_code.length !== 7 || !$.isNumeric(this.patient_form.zip_code)) {
          showErrorModal(this.$t('new_validation.patient.zipcode_greater_than_7'))
        } else {
          // eslint-disable-next-line no-new
          new YubinBangoCore(this.patient_form.zip_code, (address) => {
            this.patient_form.address = `${address.region}${address.locality}${address.street} ${address.extended || ''}`
            this.patient_form.prefecture = address.region
            this.patient_form.city = address.locality
            if (this.patient_form.address.length === 1) {
              showErrorModal(this.$t('new_validation.patient.zipcode_invalid'))
            }
          })
        }
      },
      showPersonModal: function () {
        this.is_show_modal_select_person = true
      },
      updateStaffData: function (item) {
        // check duplicate
        if (this.person_in_charges.some(value => value.staff_id === item.id)) {
          return false
        }

        this.person_in_charges.push({
          staff_id: item.id,
          staff: {
            name: item.name,
            position: {
              name: item.position_name
            }
          }
        })
        this.is_show_modal_select_person = false
      },
      removePerson: function (staffId) {
        this.person_in_charges = this.person_in_charges.filter(item => item.staff_id !== staffId)
      },
      handleMailChange (value) {
        this.errors.email = ''

        if (value.length > 80) {
          this.errors.email = this.$t('new_validation.common.maximum', { attribute: this.$t('new_validation.common.attributes.email'), max: 80 })

          return
        }

        if (value && !checkValidEmail(value)) {
          this.errors.email = this.$t('new_validation.common.invalid_email')
        }
      },
      handlePhoneChange (value) {
        this.errors.tel = ''

        if (value && checkValidPhone(value)) {
          this.errors.tel = this.$t('new_validation.patient.' + checkValidPhone(value))
        }
      },
      formatDayOrMonth (value) {
        if (value < 10) {
          return '0' + value
        }

        return value
      },
      setBrandType (value) {
        if (!this.disabledBrandType) {
          this.patient_form.brand_type = value
        }
      },
      handleZipcodeChange (value) {
        this.errors.zipcode = ''

        if (value && (value.length !== 7 || !$.isNumeric(value))) {
          this.errors.zipcode = this.$t('new_validation.patient.zipcode_greater_than_7')
        }
      },
      setReasonForNonContractModalStatus (value) {
        this.$store.commit('set_reasonForNonContractModalStatus', value)
      },
      setReasonForNonContract (value) {
        this.$store.commit('set_reasonForNonContract', value)
      }
    }
  }
</script>
