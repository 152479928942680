var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "order-detail",
        {
          "lazy-load lazy-load--big-space": _vm.is_loading_data,
          "order-detail--only-order-detail": _vm.isOnlyOrderDetail
        }
      ]
    },
    [
      _c("div", { staticClass: "order-detail__header" }, [
        _c("span", { staticClass: "order-detail__header__title" }, [
          _vm._v(_vm._s(_vm.$t("objects.orders.text.order_details")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "order-detail__action",
              {
                "order-detail__action--instruction-admin":
                  _vm.isInstructionAdmin
              },
              { "order-detail__action--lab-designer": _vm.isLabDesigner }
            ]
          },
          [
            _c(
              "div",
              { staticClass: "order-detail__action__header" },
              [
                !_vm.isLabDesigner
                  ? _c("common-button", {
                      class: [
                        "button-v2--primary button-v2--patient-info",
                        { sp: !_vm.isOnlyOrderDetail }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "patient.orders",
                            params: { id: _vm.$route.params.id }
                          })
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "objects.orders.button.patient_information"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        294785515
                      )
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__action__footer" }, [
              _c(
                "div",
                { staticClass: "order-detail__action__footer-first" },
                [
                  _vm.isShowSimulationBtn
                    ? _c("common-button", {
                        staticClass:
                          "button-v2--quaternary button-v2--open-simulation",
                        on: {
                          click: function($event) {
                            _vm.openSimulation()
                            _vm.triggerClick(
                              _vm.trigger_click_type.SIMULATION,
                              _vm.order.sf_gbangou,
                              _vm.order_id
                            )
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "objects.orders.button.open_simulation"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          520333907
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCanDownloadDataSetup && !_vm.isLabDesigner
                    ? _c("common-button", {
                        staticClass:
                          "button-v2--quaternary button-v2--download-setup",
                        on: {
                          click: function($event) {
                            _vm.downloadDataSetup()
                            _vm.triggerClick(
                              _vm.trigger_click_type.SETUP_DATA,
                              _vm.order.sf_gbangou,
                              _vm.order_id
                            )
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "objects.orders.button.download_setup_data"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1291806440
                        )
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "order-detail__action__footer-second" },
                [
                  _c("common-button", {
                    class:
                      (_vm.isLabDesigner || _vm.isInstructionAdmin
                        ? "button-v2--quaternary"
                        : "button-v2--primary") +
                      " button-v2--print-order-detail",
                    on: {
                      click: function($event) {
                        _vm.print(_vm.order_id)
                        _vm.triggerClick(
                          _vm.trigger_click_type.PRINT,
                          _vm.order.sf_gbangou,
                          _vm.order_id
                        )
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "objects.orders.button.print_order_detail"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-detail__info" }, [
        _c("div", { staticClass: "order-detail__info__col" }, [
          _c("div", { staticClass: "order-detail__info__item" }, [
            _c("div", { staticClass: "order-detail__info__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.orders.text.order_id")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__info__value" }, [
              _vm._v("\n          " + _vm._s(_vm.order.code) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "order-detail__info__item" }, [
            _c("div", { staticClass: "order-detail__info__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("new_text.clinic")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__info__value" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.clinic.formal_name) + "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "order-detail__info__item" }, [
            _c("div", { staticClass: "order-detail__info__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("new_text.patient_id")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__info__value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.intVal(_vm.patient.sf_user_id) || _vm.patient.id) +
                  "\n        "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "order-detail__info__col" }, [
          _c("div", { staticClass: "order-detail__info__item" }, [
            _c("div", { staticClass: "order-detail__info__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("objects.orders.text.g_number")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__info__value" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.order.sf_gbangou) + "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "order-detail__info__item" }, [
            _c("div", { staticClass: "order-detail__info__label" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("new_text.roman")) + "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__info__value" }, [
              _vm._v("\n          " + _vm._s(_vm.romaName) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "order-detail__info__item" }, [
            _c("div", { staticClass: "order-detail__info__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("new_text.full_name")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "order-detail__info__value" }, [
              _vm._v("\n          " + _vm._s(_vm.name) + "\n        ")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-detail__table" }, [
        _c("table", [
          _c("tbody", [
            _vm.order.sf_ipr_ruantity_image_url && !_vm.is_load_ipr_error
              ? _c("tr", [
                  !_vm.isSmartPhone
                    ? _c("td", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("objects.orders.text.ipr_amount")) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "order-detail__table__line order-detail__table__line--ipr",
                      attrs: { colspan: "2" }
                    },
                    [
                      _vm.isSmartPhone
                        ? _c(
                            "span",
                            { staticClass: "order-detail__ipr__label" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("objects.orders.text.ipr_amount")
                                ) + "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.is_loading_ipr
                        ? _c("div", {
                            staticClass: "order-detail__ipr__lazy-load"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "order-detail__ipr__img",
                        attrs: { src: _vm.iprUrl },
                        on: {
                          load: function($event) {
                            _vm.is_loading_ipr = false
                          },
                          error: function($event) {
                            _vm.is_load_ipr_error = true
                          }
                        }
                      })
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("tr", [
              !_vm.isSmartPhone
                ? _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("objects.orders.text.about_expansion")) +
                        "\n          "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass: "order-detail__expansion-row",
                  attrs: { colspan: "2" }
                },
                [
                  _vm.isSmartPhone
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("objects.orders.text.about_expansion")
                          ) + "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "order-detail__expansion-row__item" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_1u", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_1u || 0
                              )
                            })
                          ) +
                          "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_1l", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_1l || 0
                              )
                            })
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "order-detail__expansion-row__item" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_2u", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_2u || 0
                              )
                            })
                          ) +
                          "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_2l", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_2l || 0
                              )
                            })
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "order-detail__expansion-row__item" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_3u", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_3u || 0
                              )
                            })
                          ) +
                          "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_3l", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_3l || 0
                              )
                            })
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "order-detail__expansion-row__item" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_4u", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_4u || 0
                              )
                            })
                          ) +
                          "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_4l", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_4l || 0
                              )
                            })
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "order-detail__expansion-row__item" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_5u", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_5u || 0
                              )
                            })
                          ) +
                          "\n              " +
                          _vm._s(
                            _vm.$t("objects.orders.text.sf_exvolume_5l", {
                              value: _vm.sfExvolume(
                                _vm.order.sf_exvolume_5l || 0
                              )
                            })
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            !_vm.isSmartPhone
              ? _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("objects.orders.text.technician_instructions")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  Array.isArray(_vm.instructionMsg)
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "order-detail__table__child order-detail__table__child--instruction-msg"
                        },
                        [
                          _vm.instructionMsg.length !== 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "order-detail__instruction-msg"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-detail__instruction-msg__row order-detail__instruction-msg__row--header"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "order-detail__instruction-msg__col order-detail__instruction-msg__col--header"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("new_text.last_modified")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "order-detail__instruction-msg__col order-detail__instruction-msg__col--header"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("new_text.last_content")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-detail__instruction-msg__body"
                                    },
                                    _vm._l(_vm.instructionMsg, function(
                                      msg,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "order-detail__instruction-msg__row"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-detail__instruction-msg__col break-all"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(Object.keys(msg)[0]) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "order-detail__instruction-msg__col break-all"
                                            },
                                            _vm._l(
                                              Object.values(msg)[0].split(
                                                /\r?\n/
                                              ),
                                              function(str, idx) {
                                                return _c(
                                                  "span",
                                                  { key: idx },
                                                  [
                                                    _vm._l(
                                                      str.split(" "),
                                                      function(
                                                        shortStr,
                                                        shortIdx
                                                      ) {
                                                        return _c(
                                                          "span",
                                                          { key: shortIdx },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  !_vm.isValidUrl(
                                                                    shortStr
                                                                  )
                                                                    ? shortStr
                                                                    : ""
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                            _vm.isValidUrl(
                                                              shortStr
                                                            )
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href: shortStr,
                                                                      target:
                                                                        "_blank"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        shortStr
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br")
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _c("td", { staticClass: "order-detail__table__line" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.instructionMsg) +
                            "\n          "
                        )
                      ])
                ])
              : _c("tr", { staticClass: "order-detail__instruction-msg-tr" }, [
                  Array.isArray(_vm.instructionMsg)
                    ? _c("td", { attrs: { colspan: "2" } }, [
                        _c(
                          "div",
                          { staticClass: "order-detail__instruction-msg-sp" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "objects.orders.text.technician_instructions"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.instructionMsg, function(msg, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "order-detail__table__item"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "order-detail__table__date"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "order-detail__table__title"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("new_text.last_modified")
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "order-detail__table__content"
                                        },
                                        [_vm._v(_vm._s(Object.keys(msg)[0]))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "order-detail__table__message"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "order-detail__table__title"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("new_text.last_content")
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "order-detail__table__content"
                                        },
                                        _vm._l(
                                          Object.values(msg)[0].split(/\r?\n/),
                                          function(str, idx) {
                                            return _c(
                                              "span",
                                              { key: idx },
                                              [
                                                _vm._l(str.split(" "), function(
                                                  shortStr,
                                                  shortIdx
                                                ) {
                                                  return _c(
                                                    "span",
                                                    { key: shortIdx },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            !_vm.isValidUrl(
                                                              shortStr
                                                            )
                                                              ? shortStr
                                                              : ""
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                      _vm.isValidUrl(shortStr)
                                                        ? _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: shortStr,
                                                                target: "_blank"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(shortStr)
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }),
                                                _vm._v(" "),
                                                _c("br")
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _c(
                        "td",
                        {
                          staticClass: "order-detail__instruction__empty",
                          attrs: { colspan: "2" }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "objects.orders.text.technician_instructions"
                                )
                              )
                            )
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.instructionMsg) +
                              "\n          "
                          )
                        ]
                      )
                ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.orders.text.order_detail")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("objects.orders.text.product_time", {
                        name: _vm.product.name,
                        number: _vm.order.product_number || 0,
                        suffix: _vm.getOrdinalSuffix(
                          _vm.order.product_number || 0
                        )
                      })
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("objects.orders.text.order_mouthpiece")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _c("span", { staticClass: "pc" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.order.order_visit_number) +
                      "\n              "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.$t("objects.orders.text.number_treatment", {
                          number: _vm.order.sf_total_course_count
                        })
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "sp" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.order.order_visit_number) +
                      "\n              (" +
                      _vm._s(
                        _vm.$t("objects.orders.text.number_treatment", {
                          number: _vm.order.sf_total_course_count
                        })
                      ) +
                      ")\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("new_text.status")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.order.order_status
                        ? _vm.$t(
                            "objects.orders.text.status." +
                              _vm.order.order_status
                          )
                        : ""
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            !_vm.isSmartPhone
              ? _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("objects.orders.text.this_order")))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "order-detail__table__child" }, [
                    _vm.orderProducts.length !== 0
                      ? _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  class: { "not-show-price": _vm.isLabDesigner }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t("objects.product.product_name")
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.isLabDesigner
                                ? _c("th", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("objects.product.unit_price")
                                        ) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.$t("new_text.quantity")) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              !_vm.isLabDesigner
                                ? _c("th", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("new_text.subtotal")) +
                                        "\n                  "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.orderProducts, function(
                              orderProduct,
                              index
                            ) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(orderProduct.product_name) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                !_vm.isLabDesigner
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(orderProduct.unit_price) +
                                          "\n                  "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(orderProduct.quantity) +
                                      "\n                  "
                                  )
                                ]),
                                _vm._v(" "),
                                !_vm.isLabDesigner
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(orderProduct.cost) +
                                          "\n                  "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("objects.orders.text.this_order")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "order-detail__table__child" },
                    _vm._l(_vm.orderProducts, function(orderProduct, index) {
                      return _c("table", { key: index }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t("objects.product.product_name")
                                  ) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("objects.product.unit_price")) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("new_text.quantity")) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("new_text.subtotal")) +
                                  "\n                  "
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(orderProduct.product_name) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(orderProduct.unit_price) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(orderProduct.quantity) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(orderProduct.cost) +
                                  "\n                  "
                              )
                            ])
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "order-detail__clinic" }, [
        _c("div", { staticClass: "order-detail__clinic__title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("objects.orders.text.contact_laboratory")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "order-detail__clinic__item" }, [
          _c("div", { staticClass: "order-detail__clinic__label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("objects.orders.text.about_oral_data")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-detail__clinic__value" },
            [
              _c("checkbox", {
                staticClass: "order-detail__checkbox",
                attrs: {
                  "is-checked": Boolean(_vm.order.sf_data_ng),
                  disabled: true,
                  label: _vm.$t("objects.orders.text.scan_data")
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "order-detail__clinic__table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(_vm._s(_vm.$t("objects.orders.text.setup_staff")))
              ]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.workerSetup.name))]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("objects.orders.text.setup_press_cut")))
              ]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.workerPresscut.name))])
            ])
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("objects.orders.text.order_date")))
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.formatDateTime(
                      _vm.order.created_at,
                      _vm.$t("new_text.datetime.year_month_day_simple")
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("objects.orders.text.order_received_date"))
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.formatDateTime(
                      _vm.order.order_received_date,
                      _vm.$t("new_text.datetime.year_month_day_simple")
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("objects.orders.text.aligner_shipping_date"))
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.formatDateTime(
                      _vm.order.order_shipping_date,
                      _vm.$t("new_text.datetime.year_month_day_simple")
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.$t("objects.orders.text.date_shipment")))
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.formatDateTime(
                      _vm.order.sf_delivery_date,
                      _vm.$t("new_text.datetime.year_month_day_simple")
                    )
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("objects.orders.text.delivery_date")))
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.formatDateTime(
                      _vm.order.order_hope_date,
                      _vm.$t("new_text.datetime.year_month_day_simple")
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("objects.orders.text.delivery_completion_date"))
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.formatDateTime(
                      _vm.order.delivery_completed_date,
                      _vm.$t("new_text.datetime.year_month_day_simple")
                    )
                  )
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("table-list-order", {
        staticClass: "patient-orders__table--detail",
        attrs: { orders: _vm.orders, "order-id": parseInt(_vm.order_id) }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "order-list-files__wrapper" },
        [
          _c(
            "div",
            { staticClass: "order-list-files order-list-files--instruction" },
            [
              _c("div", { staticClass: "order-list-files__header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "objects.orders.text.uploaded_technician_instructions"
                      )
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "order-list-files__list order-list-files__list__show"
                },
                [
                  _c("instruction-file-detail", {
                    staticClass: "order-list-files__card--instruction",
                    attrs: {
                      order: _vm.currentOrder,
                      "is-show-download": false,
                      "is-show-print-status": true
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "order-list-files" }, [
            _c("div", { staticClass: "order-list-files__header" }, [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      _vm.is_show_order_files = !_vm.is_show_order_files
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("objects.orders.text.file_uploaded")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("img", {
                class: [
                  "order-list-files__toggle",
                  { "order-list-files__toggle__up": _vm.is_show_order_files }
                ],
                attrs: { src: "/images/icon-toggle-down.svg" },
                on: {
                  click: function($event) {
                    _vm.is_show_order_files = !_vm.is_show_order_files
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "order-list-files__list",
                  { "order-list-files__list__show": _vm.is_show_order_files }
                ]
              },
              _vm._l(_vm.orderFiles, function(file) {
                return _c("order-file-detail", {
                  key: file.id,
                  attrs: {
                    "order-file": file,
                    "sf-order-id": _vm.order.sf_order_id
                  }
                })
              }),
              1
            )
          ]),
          _vm._v(" "),
          _vm.hasAllowsUpdateMoreFile
            ? _c("UploadMoreMediaFile", {
                on: {
                  "update-data": _vm.updateFiles,
                  "upload-files": _vm.submitUploadFiles
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }